import API from "../configs/axiosInstance";

const createDomain = (data = {}) => {
    return API({
        url: `/account-service/v1/domains`,
        method: 'POST',
        data
    });
};

const createIps = (data = {}) => {
    return API({
        url: `/account-service/v1/domains/ips`,
        method: 'POST',
        data
    });
};

const getDomainsFiltered = (params = {}, data = {}) => {
    return API({
        url: `/account-service/v1/domains/pageable`,
        method: 'POST',
        data,
        params
    });
}

const deleteDomain = (data) => {
    return API({
        url: `/account-service/v1/domains/${data}`,
        method: 'DELETE',
        data
    });
}

const downloadFileDomain = (data) => {
    return API({
        url: `/account-service/v1/pdf-templates/pdf/${data.id}`,
        method: 'POST',
        responseType: 'arraybuffer',
        data
    });
}

const triggerDomainScan = id => {
    return API({
        url: `/account-service/v1/domains/${id}/scan-trigger`,
        method: 'POST',
        responseType: 'arraybuffer'
    });
}

const getHistory = (data = {}, params = {}) => {
    return API({
        url: '/account-service/v1/domains/all-history',
        method: 'POST',
        data,
        params
    })
}

const getDomainCount = () => {
    return API({
        url: '/account-service/v1/domains/count?type=DOMAIN',
    })
}

const getSubDomainCount = () => {
    return API({
        url: '/account-service/v1/domains/count?type=SUB_DOMAIN',
    })
}

const getIpCount = () => {
    return API({
        url: '/account-service/v1/domains/count?type=IP',
    })
}

const getCriticalLevelsByDomain = (data) => {
    return API({
        url: `/account-service/v1/tasks/domain-critical-level-count?domainId=${data}`,
    })
}

const getDetailSubDomains = (data, params = {}) => {
    return API({
        url: `/account-service/v1/domains/${data}/subdomains`,
        params
    })
}

const getDomainIssues = (data, params = {}) => {
    return API({
        url: `/account-service/v1/tasks/by-domain?domainId=${data}`,
        params
    })
}

const getAllDomains = (params = {}) => {
    return API({
        url: `/account-service/v1/domains/search?name=`,
        method: 'GET',
        params
    });
}

const getStatus = (data = {}) => {
    return API({
        url:`/account-service/v1/domains/${data}/scan-status`,
        method: 'GET',
        data
    });
}

const domainService = {
    createDomain,
    createIps,
    getDomainsFiltered,
    deleteDomain,
    downloadFileDomain,
    getHistory,
    triggerDomainScan,
    getDomainCount,
    getSubDomainCount,
    getIpCount,
    getCriticalLevelsByDomain,
    getDetailSubDomains,
    getDomainIssues,
    getAllDomains,
    getStatus
};

export default domainService;
