import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API from "../../configs/axiosInstance";

export const initialState = {
	loading: false,
	detailLoading: false,
	error: '',
	success: false,
	message: '',
	messageType: '',
	domainList: [],
	domainDetails: null,
	lastScanId: null,
	domainDetailsVulnerabilities: null,
	domainDetailsVulnerabilityDetails: [],
	domainHistoryList: [],
	domainHistoryNameList: [],
	vulnerabilityRemediationLoading: false,
	vulnerabilityRemediationDetails: {},
};

export const networkQuery = createAsyncThunk(
	'network/networkQuery',
	async (data, { rejectWithValue, dispatch }) => {
		const name = data.name;
		const taskScheduler = {
			frequency: data.taskScheduler.frequency,
			period: data.taskScheduler.period.toUpperCase(),
		};

		try {
			const response = await API.post('/account-service/v1/domains', {
				name,
				taskScheduler,
			});

			dispatch(domainQuerySuccess(response.data));

			console.log('sorgu başarılı');

			return response.data;
		} catch (error) {
			if (error.response.data.status == 50) {
				console.log('domain daha önce eklenmiş');
				return rejectWithValue(
					dispatch(
						domainQueryError(
							'Domain daha önce eklenmiş. Lütfen farklı bir domain deneyiniz.'
						)
					)
				);
			}
			return rejectWithValue(
				dispatch(
					domainQueryError(error.response?.data?.error || 'Bir Hata Oluştu')
				)
			);
		}
	}
);

export const getDomainList = createAsyncThunk(
	'network/getDomainList',
	async (data, { rejectWithValue, dispatch }) => {
		const name = data.name || '';
		const ip = data.ip || '';
		const createdDate = data.createdDate || '';

		try {
			const response = await API.post('/account-service/v1/domains/pageable', {
				name,
				ip,
				createdDate,
			});

			console.log('response', response);

			dispatch(domainListSuccess(response.data));

			return response.data;
		} catch (error) {
			return rejectWithValue(
				dispatch(
					domainListError(error.response?.data?.error || 'Bir Hata Oluştu')
				)
			);
		}
	}
);

export const deleteDomain = createAsyncThunk(
	'network/deleteDomain',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const response = await API.delete(`/account-service/v1/domains/${data}`);

			dispatch(domainDeleteSuccess({ id: data }));

			console.log('domain silme başarılı');

			return response.data;
		} catch (error) {
			return rejectWithValue(
				dispatch(
					domainDeleteError(error.response?.data?.error || 'Bir Hata Oluştu')
				)
			);
		}
	}
);

export const deleteDomainHistory = createAsyncThunk(
	'network/deleteDomain',
	async (data, { rejectWithValue, dispatch }) => {

		try {
			const response = await API.delete(`/account-service/v1/domains/${data}`);

			dispatch(domainDeleteHistorySuccess({ id: data }));

			return response.data;
		} catch (error) {
			return rejectWithValue(
				dispatch(
					domainDeleteHistoryError(
						error.response?.data?.error || 'Bir Hata Oluştu'
					)
				)
			);
		}
	}
);

export const domainDetail = createAsyncThunk(
	'network/domainDetail',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const response = await API.get(
				`/account-service/v1/domains/${data}/details`
			);

			dispatch(domainDetailsSuccess(response.data));

			console.log('domain detayı başarılı');

			return response.data;
		} catch (error) {
			return rejectWithValue(
				dispatch(
					domainDetailsError(error.response?.data?.error || 'Bir Hata Oluştu')
				)
			);
		}
	}
);

export const getDomainHistoryList = createAsyncThunk(
	'network/getDomainHistoryList',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const response = await API.get(
				`/account-service/v1/domains/history/${data.id}`,
				{params: data.params}
			);

			dispatch(domainHistoryListSuccess(response.data));

			console.log('domain history listesi başarılı');

			return response.data;
		} catch (error) {
			return rejectWithValue(
				dispatch(
					domainHistoryListError(
						error.response?.data?.error || 'Bir Hata Oluştu'
					)
				)
			);
		}
	}
);

export const getDetailVulnerabilities = createAsyncThunk(
	"network/getDetailVulnerabilities",
	async (data, { rejectWithValue }) => {
		try {
			const response = await API.get(
				`/account-service/v1/domains/${data}/vulnerabilities`,
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getDetailVulnerabilityDetails = createAsyncThunk(
	"network/getDetailVulnerabilityDetails",
	async (data, { rejectWithValue }) => {
		try {
			const response = await API.get(
				`/account-service/v1/plugins/${data}`,
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getDomainHistoryNameList = createAsyncThunk(
	"network/getDomainHistoryNameList",
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const response = await API.get(
				`/account-service/v1/domains/${data}/history`,
			);

			dispatch(setLastHistoryNamesListParentId(data));
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getDetailedRemediationSteps = createAsyncThunk(
	"network/getDetailedRemediationSteps",
	async (data, { rejectWithValue }) => {
		try {
			const response = await API.post(
				`/account-service/v1/open-ai/plugin-remedy-vulnerability`,
				data
			);
			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const networkSlice = createSlice({
	name: 'network',
	initialState,
	reducers: {
		domainQuerySuccess: (state, action) => {
			state.loading = false;
			state.success = true;
			state.message = 'Domain sorgulama başarılı';
			state.messageType = 'success';
			state.error = '';
			state.data = action.payload;
		},
		domainQueryError: (state, action) => {
			state.loading = false;
			state.error = action.payload;
			state.success = false;
			state.message = action.payload || 'Bir Hata Oluştu';
			state.messageType = 'error';
		},
		domainListSuccess: (state, action) => {
			state.domainList = action.payload;
			state.loading = false;
			state.success = true;
			state.message = 'Domain listesi başarılı';
			state.messageType = 'success';
			state.error = '';
		},
		domainListError: (state, action) => {
			state.loading = false;
			state.error = action.payload;
			state.success = false;
			state.message = action.payload || 'Bir Hata Oluştu';
			state.messageType = 'error';
		},
		domainDetailsClear: (state, actio) => {
			state.domainDetails = null;
		},
		domainDeleteSuccess: (state, action) => {
			state.domainList.content = state.domainList.content.filter(
				(item) => item.id !== action.payload.id
			);
			state.loading = false;
			state.success = true;
			state.message = 'Domain silme başarılı';
			state.messageType = 'success';
			state.error = '';
		},
		domainDeleteError: (state, action) => {
			state.loading = false;
			state.error = action.payload;
			state.success = false;
			state.message = action.payload || 'Bir Hata Oluştu';
			state.messageType = 'error';
		},
		domainDetailsSuccess: (state, action) => {
			state.domainDetails = action.payload;
			state.loading = false;
			state.detailLoading = false;
			state.success = true;
			state.message = 'Domain detayları başarılı';
			state.messageType = 'success';
			state.error = '';
		},
		domainDetailsError: (state, action) => {
			state.loading = false;
			state.detailLoading = false;
			state.error = action.payload;
			state.success = false;
			state.message = action.payload || 'Bir Hata Oluştu';
			state.messageType = 'error';
		},
		domainHistoryListSuccess: (state, action) => {
			state.domainHistoryList = action.payload;
			state.loading = false;
			state.success = true;
			state.message = 'Domain geçmiş listesi başarılı';
			state.messageType = 'success';
			state.error = '';
		},
		domainHistoryListError: (state, action) => {
			state.loading = false;
			state.error = action.payload;
			state.success = false;
			state.message = action.payload || 'Bir Hata Oluştu';
			state.messageType = 'error';
		},
		domainHistoryDeleteSuccess: (state, action) => {
			state.domainHistoryList.content = state.domainHistoryList.content.filter(
				(item) => item.id !== action.payload.id
			);
			state.loading = false;
			state.success = true;
			state.message = 'Domain geçmiş silme başarılı';
			state.messageType = 'success';
			state.error = '';
		},
		domainHistoryDeleteError: (state, action) => {
			state.loading = false;
			state.error = action.payload;
			state.success = false;
			state.message = action.payload || 'Bir Hata Oluştu';
			state.messageType = 'error';
		},
		resetVulnerabilityDetails: (state, action) => {
			state.domainDetailsVulnerabilityDetails = [];
		},
        setLastHistoryNamesListParentId: (state, action) => {
			state.lastScanId = action.payload;
		},
		resetDetailedRemediationSteps: (state, action) => {
			state.vulnerabilityRemediationDetails = {};
		}
	},
	extraReducers: {
		[networkQuery.pending]: (state, action) => {
			state.loading = true;
		},
		[getDomainList.pending]: (state, action) => {
			state.loading = true;
		},
		[domainDetail.pending]: (state, action) => {
			state.detailLoading = true;
			state.loading = true;
			state.domainDetails = null;
		},
		[deleteDomain.pending]: (state, action) => {
			state.loading = true;
		},
		[getDomainHistoryList.pending]: (state, action) => {
			state.loading = true;
			state.domainHistoryList=[];
		},
		[deleteDomainHistory.pending]: (state, action) => {
			state.loading = true;
		},
		[getDetailVulnerabilities.pending]: (state, action) => {
			state.loading = true;
			state.domainDetailsVulnerabilities = null;
		},
		[getDetailVulnerabilities.rejected]: (state, action) => {
			state.loading = false;
		},
		[getDetailVulnerabilities.fulfilled]: (state, action) => {
			state.loading = false;
			state.domainDetailsVulnerabilities = action.payload;
		},
		[getDetailVulnerabilityDetails.fulfilled]: (state, action) => {
			state.domainDetailsVulnerabilityDetails = [...state.domainDetailsVulnerabilityDetails, action.payload];
		},
		[getDomainHistoryNameList.fulfilled]: (state, action) => {
			state.domainHistoryNameList=action.payload;
			state.loading = false;
			state.success = true;
			state.message = 'Domain geçmiş listesi başarılı';
			state.messageType = 'success';
			state.error = '';
		},
		[getDomainHistoryNameList.pending]: (state, action) => {
			state.domainHistoryNameList=[];
		},
		[getDomainHistoryNameList.rejected]: (state, action) => {
			state.domainHistoryNameList=[];
			state.loading = false;
			state.error = action.payload;
			state.success = false;
			state.message = action.payload || 'Bir Hata Oluştu';
			state.messageType = 'error';
		},
		[getDetailedRemediationSteps.fulfilled]: (state, action) => {
			state.vulnerabilityRemediationDetails = action?.payload;
			state.vulnerabilityRemediationLoading = false;
		},
		[getDetailedRemediationSteps.pending]: (state, action) => {
			state.vulnerabilityRemediationLoading = true;
		},
		[getDetailedRemediationSteps.rejected]: (state, action) => {
			state.vulnerabilityRemediationLoading = false;
		}
	},
});

export const {
	domainQuerySuccess,
	domainQueryError,
	domainListError,
	domainListSuccess,
	domainDetailsClear,
	domainDeleteSuccess,
	domainDeleteError,
	domainDetailsSuccess,
	domainDetailsError,
	domainHistoryListSuccess,
	domainHistoryListError,
	domainDeleteHistoryError,
	domainDeleteHistorySuccess,
	resetVulnerabilityDetails,
	setLastHistoryNamesListParentId,
	resetDetailedRemediationSteps
} = networkSlice.actions;

export default networkSlice.reducer;
