import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import settingsService from 'services/settingsService';

const initialState = {
    currentTab: 0,
    settingsBurger: false,
    ticketError: null,
    ticketSuccess: null,
    loginHistory: null,
    currentNotificationSettings: null,
    domainExpireEnable: false,
    sslExpireEnable: false,
    domainExpireAlertDay: '7',
    sslExpireAlertDay: '7',
    isLoading: true,
    isChangingPasswordSuccess: null,
    apiKey: null,
    apiKeyError: null,
    apiKeySuccess: null,
};

export const getAllTags = createAsyncThunk("tags/getAllTags", async ( data,{
    rejectWithValue
}) => {
    try {
        return await settingsService.getAllTags(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const sendTickets = createAsyncThunk("tickets/sendTickets", async ( data,{
    rejectWithValue
}) => {
    try {
        return await settingsService.sendTickets(data);
    }
    catch (error) {
        const errorMessage = error.response?.data?.message || error.message || 'Unknown error occurred';
        return rejectWithValue(errorMessage); 
    }
});

export const getLoginHistory = createAsyncThunk(
    "settings/getLoginHistory",
    async ({ params, data }, { rejectWithValue }) => {
        try {
            return await settingsService.getLoginHistory(params, data);
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getCurrentNotificationSettings = createAsyncThunk("settings/getCurrentNotificationSettins", async (data, {
    rejectWithValue
}) => {
    try {
        return await settingsService.getCurrentNotificationSettings();
    }
    catch (error) {
        return rejectWithValue(error);
    }
})

export const updateNotificationSettings = createAsyncThunk("settings/updateNotificationSettings", async (data, {
    rejectWithValue
}) => {
    try {
        return await settingsService.updateNotificationSettings(data);
    }
    catch (error) {
        return rejectWithValue(error);
    }
})

export const updatePasswordWhileLoggedIn = createAsyncThunk("settings/updatePasswordWhileLoggedIn", async (data, {
    rejectWithValue
}) => {
    try {
        return await settingsService.updatePasswordWhileLoggedIn(data);
    }
    catch (error) {
        return rejectWithValue(error);
    }
})

export const createApiKey = createAsyncThunk("settings/createApiKey", async (data, { rejectWithValue }) => {
    try {
        return await settingsService.createApiKey(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const updateApiKey = createAsyncThunk("settings/updateApiKey", async ({ keyId, data }, { rejectWithValue }) => {
    try {
        return await settingsService.updateApiKey(keyId, data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getApiKey = createAsyncThunk("settings/getApiKey", async (_, { rejectWithValue }) => {
    try {
        return await settingsService.getApiKey();
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const settingsSlice = createSlice({
    name: 'settingsSlice',
    initialState,
    reducers: {
        setCurrentTab: (state, action) => {
            state.currentTab = action.payload;
        },
        setSettingsBurger: (state, action) => {
            state.settingsBurger = action.payload;
        },
        clearTicketError: (state) => {
            state.ticketError = null
        },
        clearTicketSuccess: (state) => {
            state.ticketSuccess = null
        },
        setDomainExpireEnable: (state, action) => {
            state.domainExpireEnable = action.payload;
        },
        setSslExpireEnable: (state, action) => {
            state.sslExpireEnable = action.payload;
        },
        setDomainExpireAlertDay: (state, action) => {
            state.domainExpireAlertDay = action.payload;
        },
        setSslExpireAlertDay: (state, action) => {
            state.sslExpireAlertDay = action.payload;
        },
        clearApiKeyError: (state) => {
            state.apiKeyError = null;
        },
        clearApiKeySuccess: (state) => {
            state.apiKeySuccess = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllTags.fulfilled, (state, action) => {
            state.allTags = action.payload.data;
            state.loading = false;
        });

        builder.addCase(sendTickets.fulfilled, (state, action) => {
            if(action?.payload?.response?.data?.error){
                state.ticketError = action.payload.response.status;
            } else {
                state.ticketSuccess = action?.payload?.status;
            }
        });
        builder.addCase(sendTickets.rejected, (state, action) => {
            state.ticketError = action?.payload?.response?.data?.error;
        });
        builder.addCase(getLoginHistory.fulfilled, (state, action) => {
            state.loginHistory = action.payload.data;
        });
        builder.addCase(getCurrentNotificationSettings.fulfilled, (state, action) => {
            const settings = action.payload.data;
            state.currentNotificationSettings = settings;
            state.domainExpireEnable = settings.domainExpireEnable;
            state.sslExpireEnable = settings.sslExpireEnable;
            state.domainExpireAlertDay = settings.domainExpireAlertDay.toString();
            state.sslExpireAlertDay = settings.sslExpireAlertDay.toString();
            state.isLoading = false;
        });
        builder.addCase(getCurrentNotificationSettings.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(getCurrentNotificationSettings.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updatePasswordWhileLoggedIn.fulfilled, (state, action) => {
            if (action?.payload?.response) {
                state.isChangingPasswordSuccess = action.payload.response.status;
            } else {
                state.isChangingPasswordSuccess = action.payload.status;
            }
        });
        builder.addCase(createApiKey.fulfilled, (state, action) => {
            state.apiKey = action.payload.data;
            state.apiKeySuccess = true;
            if (action?.payload?.response?.status === 500) {
                state.apiKeyError = true;
            } else if (action?.payload?.status === 200){
                state.apiKeySuccess = true;
            }
        })
        builder.addCase(getApiKey.fulfilled, (state, action) => {
            state.apiKey = action.payload.data;
        })
        builder.addCase(updateApiKey.fulfilled, (state, action) => {
            if (action?.payload?.response?.status === 500) {
                state.apiKeyError = true;
            } else if (action?.payload?.status === 200){
                state.apiKeySuccess = true;
                state.apiKey = action?.payload?.data;
            }      
        })
    }
});

export const {
    setCurrentTab,
    setSettingsBurger,
    clearTicketError,
    clearTicketSuccess,
    setDomainExpireEnable,
    setSslExpireEnable,
    setDomainExpireAlertDay,
    setSslExpireAlertDay,
    clearApiKeyError,
    clearApiKeySuccess,
} = settingsSlice.actions;

export default settingsSlice.reducer;