import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import emailLeakageService from "../../services/emailLeakageService";

export const getAllEmailLeakages = createAsyncThunk(
  "emailLeakage/allEmailLeakages",
  async ({ domainName, createdDate }, { rejectWithValue }) => {
    try {
      const response = await emailLeakageService.getAllEmailLeakages(
        domainName,
        createdDate
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getScanStatus = createAsyncThunk(
  "emailLeakage/scanStatus",
  async (scanId, { rejectWithValue }) => {
    try {
      const response = await emailLeakageService.getScanStatus(scanId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getScanSummary = createAsyncThunk(
  "emailLeakage/scanSummary",
  async ({ scanId, by }, { rejectWithValue }) => {
    try {
      const response = await emailLeakageService.getScanSummary(scanId, by);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getScanEventResults = createAsyncThunk(
  "emailLeakage/scanEventResults",
  async ({ scanId, eventType }, { rejectWithValue }) => {
    try {
      const response = await emailLeakageService.getScanEventResults(
        scanId,
        eventType
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const startEmailLeakageScan = createAsyncThunk(
  "emailLeakage/startEmailLeakageScan",
  async (domainId, { rejectWithValue }) => {
    try {
      const response = await emailLeakageService.startEmailLeakageScan(
        domainId
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMailSearchByDomain = createAsyncThunk(
    "emailLeakage/mailSearchByDomain",
    async (domainId, { rejectWithValue }) => {
      try {
        const response = await emailLeakageService.getMailSearchByDomain(domainId);
        return response.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
);

export const searchLeakageByEmail = createAsyncThunk(
    "emailLeakage/searchLeakageByEmail",
    async ({ leakageMailId, email }, { rejectWithValue }) => {
      try {
        const response = await emailLeakageService.searchLeakageByEmail(
            leakageMailId,
            email
        );
        return response.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
);

export const searchLeakageByCustomEmail = createAsyncThunk(
    "emailLeakage/searchLeakageByCustomEmail",
    async ({ email }, { rejectWithValue }) => {
      try {
        const response = await emailLeakageService.searchLeakageByCustomEmail(
            email
        );
        return response.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
);

export const scanNewLeakage = createAsyncThunk(
    "emailLeakage/scanNewLeakage",
    async ({ id, name }, { dispatch, rejectWithValue }) => {
      try {
        await dispatch(getMailSearchByDomain(id)).unwrap();

        const newData = {
          data: {
            domainName: name || null,
            createDate: null,
          },
          params: {
            page: 0,
            size: 6,
          },
        };

        await dispatch(getEmailLeakagesPageable(newData)).unwrap();
      } catch (error) {
        console.error("Error during scan:", error);
        return rejectWithValue(error.message);
      }
    }
);


export const getEmailLeakagesPageable = createAsyncThunk(
    "emailLeakage/getEmailLeakagesPageable",
    async (data, { rejectWithValue }) => {
      try {
        const response = await emailLeakageService.getEmailLeakagesPageable(
            data?.params, data?.data
        );
        return response.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
);

export const postMailLeakagesVip = createAsyncThunk(
  "emailLeakage/postMailLeakagesVip",
  async (formattedData, { rejectWithValue }) => {
    try {
      const response = await emailLeakageService.postMailLeakagesVip(formattedData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const getVipEmailLeakagesPageable = createAsyncThunk(
  "emailLeakage/getVipEmailLeakagesPageable",
  async (data, { rejectWithValue }) => {
    try {
      const response = await emailLeakageService.getVipEmailLeakagesPageable(
          data?.params, data?.data
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getVipEmailLeakagesDetails = createAsyncThunk(
  "emailLeakage/getVipEmailLeakagesDetails",
  async (id, { rejectWithValue }) => {
    try {
      const response = await emailLeakageService.getVipEmailLeakagesDetails(id);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  emailLeakages: [],
  emailLeakageLoading: false,
  scanStatus: {},
  scanSummary: {},
  scanEventResults: [],
  selectedEmailLeakage: {},
  emailLeakageName: "",
  detailLoading: false,
  canRefresh:false,
  fetchedEmails: [],
  leakedEmailData: {},
  leakedEmailLoading: false,
  leakedCustomEmailData: {},
  customLeakedEmailLoading: false,
  addingVipEmailsLoading: false,
  addingVipEmailsError: '',
  vipEmailGroups: [],
  vipEmailGroupsLoading: false,
  vipEmailGroupsDetails: [],
  vipEmailGroupsDetailsLoading: false,
  leakedEmailDetailLoading: false
};

export const emailLeakageSlice = createSlice({
  name: "emailLeakageSlice",
  initialState,
  reducers: {
    selectedEmailLeakage: (state, action) => {
      state.selectedEmailLeakage = action.payload;
    },
    emailLeakageName: (state, action) => {
      state.emailLeakageName = action.payload;
    },
    setLeakedCustomEmailDataEmpty: (state, action) => {
      state.leakedCustomEmailData = {};
    },
    setAddingVipEmailsErrorEmpty: (state, action) => {
      state.addingVipEmailsError = '';
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllEmailLeakages.fulfilled, (state, action) => {
      state.emailLeakages = action.payload.content;
      state.emailLeakageLoading = false;
    });

    builder.addCase(getAllEmailLeakages.pending, (state) => {
      state.emailLeakages = [];
      state.emailLeakageLoading = true;
    });

    builder.addCase(getAllEmailLeakages.rejected, (state) => {
      state.emailLeakageLoading = false;
    });

    builder.addCase(getScanStatus.fulfilled, (state, action) => {
      state.scanStatus = action.payload;
      state.detailLoading = false;
    });

    builder.addCase(getScanStatus.rejected, (state) => {
      state.scanStatus = {};
    });

    builder.addCase(getScanStatus.pending, (state) => {
      state.scanStatus = {};
      state.detailLoading = true;
    });

    builder.addCase(getScanSummary.fulfilled, (state, action) => {
      state.scanSummary = action.payload;
      state.detailLoading = false;
    });

    builder.addCase(getScanSummary.pending, (state) => {
      state.detailLoading = true;
    });

    builder.addCase(getScanSummary.rejected, (state) => {
      state.detailLoading = false;
    });

    builder.addCase(getScanEventResults.fulfilled, (state, action) => {
      state.scanEventResults = action.payload;
      state.detailLoading = false;
    });

    builder.addCase(getScanEventResults.rejected, (state) => {
      state.scanEventResults = [];
    });

    builder.addCase(getScanEventResults.pending, (state) => {
      state.scanEventResults = [];
      state.detailLoading = true;
    });
    builder.addCase(startEmailLeakageScan.fulfilled, (state) => {
      state.canRefresh = true;
    });
    builder.addCase(startEmailLeakageScan.rejected, (state) => {
      state.canRefresh = false;
    });
    builder.addCase(startEmailLeakageScan.pending, (state) => {
      state.canRefresh = false;
    });

    builder.addCase(getMailSearchByDomain.fulfilled, (state, action) => {
      state.fetchedEmails = action.payload;
    });
    builder.addCase(searchLeakageByEmail.fulfilled, (state, action) => {
      state.leakedEmailData = action.payload;
      state.leakedEmailDetailLoading = false;
    });
    builder.addCase(searchLeakageByEmail.pending, (state, action) => {
      state.leakedEmailData = action.payload;
      state.leakedEmailDetailLoading = true;
    });
    builder.addCase(searchLeakageByEmail.rejected, (state, action) => {
      state.leakedEmailData = action.payload;
      state.leakedEmailDetailLoading = false;
    });
    builder.addCase(searchLeakageByCustomEmail.fulfilled, (state, action) => {
      state.leakedCustomEmailData = action.payload;
      state.customLeakedEmailLoading = false;
    });
    builder.addCase(searchLeakageByCustomEmail.pending, (state, action) => {
      state.customLeakedEmailLoading = true;
    });
    builder.addCase(searchLeakageByCustomEmail.rejected, (state, action) => {
      state.leakedCustomEmailData = {};
      state.customLeakedEmailLoading = false;
    });
    builder.addCase(getEmailLeakagesPageable.fulfilled, (state, action) => {
      state.fetchedEmails = action.payload;
      state.leakedEmailLoading = false;
    });
    builder.addCase(getEmailLeakagesPageable.pending, (state) => {
      state.leakedEmailLoading = true;
    });
    builder.addCase(getEmailLeakagesPageable.rejected, (state) => {
      state.leakedEmailLoading = false;
    });
    builder.addCase(postMailLeakagesVip.fulfilled, (state, action) => {
      state.addingVipEmailsError = action?.payload?.status ? action?.payload?.status : action?.payload?.response?.status;
      state.addingVipEmailsLoading = false;
    });
    builder.addCase(postMailLeakagesVip.pending, (state, action) => {
      state.addingVipEmailsLoading = true;
    });
    builder.addCase(postMailLeakagesVip.rejected, (state, action) => {
      state.addingVipEmailsLoading = false;
    });
    builder.addCase(getVipEmailLeakagesPageable.fulfilled, (state, action) => {
      state.vipEmailGroups = action.payload.data;
      state.vipEmailGroupsLoading = false;
    });
    builder.addCase(getVipEmailLeakagesPageable.pending, (state, action) => {
      state.vipEmailGroupsLoading = true;
    });
    builder.addCase(getVipEmailLeakagesPageable.rejected, (state, action) => {
      state.vipEmailGroupsLoading = false;
    });
    builder.addCase(getVipEmailLeakagesDetails.fulfilled, (state, action) => {
      state.vipEmailGroupsDetails = action.payload.data;
      state.vipEmailGroupsDetailsLoading = false;
    });
    builder.addCase(getVipEmailLeakagesDetails.pending, (state, action) => {
      state.vipEmailGroupsDetailsLoading = true;
    });
    builder.addCase(getVipEmailLeakagesDetails.rejected, (state, action) => {
      state.vipEmailGroupsDetailsLoading = false;
    });
  },
});

export const {
  selectedEmailLeakage,
  scanStatus,
  scanSummary,
  emailLeakages,
  emailLeakageName,
  setLeakedCustomEmailDataEmpty,
  setAddingVipEmailsErrorEmpty
} = emailLeakageSlice.actions;

export default emailLeakageSlice.reducer;
