import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import newsService from "../../services/newsService";

export const getThreatIntelNews = createAsyncThunk(
  "news/getThreatIntelNews",
  async ({title}, { rejectWithValue }) => {
    try {
      const response = await newsService.getThreatIntelNews(title);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchByDomain = createAsyncThunk(
  "news/fetchByDomain",
  async (domainId, { rejectWithValue }) => {
    try {
      const response = await newsService.fetchByDomain(domainId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDomainNews = createAsyncThunk(
  "news/getDomainNews",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await newsService.getDomainNews();
      return response?.data
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDomainNewsDetails = createAsyncThunk(
  "news/getDomainNewsDetails",
  async ( data , { rejectWithValue }) => {
    try {
      const response = await newsService.getDomainNewsDetails(data?.data, data?.params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  threatIntelNews: [],
  threatIntelNewsLoading: false,
  domainNews: [],
  domainNewsLoading: true,
  domainNewsDetails: null,
  domainNewsDetailsLoading: false,
};

export const newsSlice = createSlice({
  name: "newsSlice",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {

    builder.addCase(getThreatIntelNews.fulfilled, (state, action) => {
      state.threatIntelNews = action.payload;
      state.threatIntelNewsLoading = false;
    });

    builder.addCase(getThreatIntelNews.pending, (state) => {
      state.threatIntelNewsLoading = true;
    });

    builder.addCase(getThreatIntelNews.rejected, (state) => {
      state.threatIntelNewsLoading = false;
    });

    builder.addCase(getDomainNewsDetails.fulfilled, (state, action) => {
      state.domainNewsDetails = action.payload;
    });

    builder.addCase(getDomainNewsDetails.rejected, (state) => {
      state.domainNewsDetails = [];
    });

    builder.addCase(getDomainNewsDetails.pending, (state) => {
      state.domainNewsDetails = [];
      state.domainNewsDetailsLoading = true;
    });

    builder.addCase(getDomainNews.fulfilled, (state, action) => {
      state.domainNews = action.payload;
      state.domainNewsLoading = false;
    });

    builder.addCase(getDomainNews.pending, (state) => {
      state.domainNews = [];
      state.domainNewsLoading = true;
    });

    builder.addCase(getDomainNews.rejected, (state, action) => {
      state.domainNews = [];
      state.domainNewsLoading = false;
    });
  },
});

export const {
} = newsSlice.actions;

export default newsSlice.reducer;
