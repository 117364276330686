import API from "../configs/axiosInstance";

const getServiceList = () => {
    return API({
        url: `/account-service/v1/port-services/all`,
        method: 'GET',
    });
};

const getVulnerability = () => {
    return API({
        url: `/account-service/v1/vulnerabilities/all`,
        method: 'GET',
    });
};

const addVulnerability = (data = {}) => {
    return API({
        url: `/account-service/v1/vulnerabilities`,
        method: 'POST',
        data
    });
};

const saveVulnerability = (data = {}) => {
    return API({
        url: `/account-service/v1/port-services/${data.id}/vulnerabilities`,
        method: 'POST',
        data: data.selectedValues
    });
};

const vulnerableById = (id) => {
    return API({
        url: `/account-service/v1/port-services/vulnerable-by-id/${id}`,
        method: 'POST',
    });
};

const vulnerableByPort = (id) => {
    return API({
        url: `/account-service/v1/port-services/vulnerable-by-port-service/${id}`,
        method: 'POST',
    });
};

const getVulnerabilityDetected = () => {
    return API({
        url: `account-service/v1/port-services/vulnerable?size=500`,
        method: 'POST',
    });
};
const getSearchVulnerability = (search) => {
    return API({
        url: `account-service/v1/mend-vulnerabilities/${search}`,
        method: 'GET',
    });
};
const getAllVulnerabilities = (data = {}) => {
    return API({
        url: `account-service/v1/asset-vulnerabilities/all`,
        method: 'POST',
        data,
    });
};

const vulnerabilityService = {
    getServiceList,
    getVulnerability,
    addVulnerability,
    vulnerableById,
    vulnerableByPort,
    saveVulnerability,
    getVulnerabilityDetected,
    getSearchVulnerability,
    getAllVulnerabilities
};

export default vulnerabilityService;
