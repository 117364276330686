import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import socialService from 'services/socialService';

const initialState = {
    networkType: [
        {value: 'TWITTER', label: 'Twitter'},
        {value: 'INSTAGRAM', label: 'Instagram'},
        {value: 'LINKEDIN', label: 'Linkedin'},
        {value: 'FACEBOOK', label: 'Facebook'},
    ],
    periodOptions: [
        {value: 'DAY', label: 'Daily'},
        {value: 'MONTH', label: 'Monthly'},
    ],
    scanResult: null,
    socialSelectedTab: 'ALL',
    scanResultWithType: null,
    selectedDateResult: null,
    loading: false,
    error: false,
    success: false,
    secondPhase: false,
    defaultSearcherRequest: {
        networkType: null,
        domainName: "",
    },
    selectedDateResultId: {},
    scanResultsByIdLoading: false,
    selectedDateResultName: {},
    selectedResultFinalData: {},
};

export const socialScan = createAsyncThunk("social/scan", async (data, {
    rejectWithValue
}) => {
    try {
        return await socialService.socialScan(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const socialSearcher = createAsyncThunk("social/seacher", async (data, {
    rejectWithValue
}) => {
    try {
        return await socialService.socialSearcher(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const socialScanTrigger = createAsyncThunk("social/scanTrigger", async (data, {
    rejectWithValue
}) => {
    try {
        return await socialService.socialScanTrigger(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const socialScanResultsById = createAsyncThunk("social/scanResultsById", async (data, {
    rejectWithValue
}) => {
    try {
        return await socialService.socialScanResultsById(data.id);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const socialSlice = createSlice({
    name: 'socialSlice',
    initialState,
    reducers: {
        resetPhase: (state, action) => {
            state.secondPhase = false;
        },
        selectedResult: (state, action) => {
            state.selectedDateResult = action.payload;
        },
        selectedResultId: (state, action) => {
            state.selectedDateResultId = action.payload;
        },
        selectedResultName: (state, action) => {
            state.selectedDateResultName = action.payload;
        },
        selectResultFinalData: (state, action) => {
            state.selectedResultFinalData = action.payload;
        },
        setSocialSelectedTab: (state, action) => {
            state.socialSelectedTab = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(socialScan.fulfilled, (state, action) => {
            state.loading = false;

            if(action.payload?.response?.data?.error) {
                state.error = true;
            } else {
                state.error = false;
            }
        });

        builder.addCase(socialScan.pending, (state, action) => {
            state.loading = true;
            state.secondPhase = true;
            state.error = false;
        });

        builder.addCase(socialScan.rejected, (state, action) => {
            state.loading = false;
            state.error = true;
        });

        builder.addCase(socialSearcher.fulfilled, (state, action) => {
            if(action?.payload?.message) {
                state.error = true;
                state.loading = false;
            } else {
                state.loading = false;
                if(state.socialSelectedTab === 'ALL') {
                    state.scanResult = action.payload.data;
                } else {
                    state.scanResultWithType = action.payload.data;
                }
            }
        });

        builder.addCase(socialSearcher.pending, (state, action) => {
            state.loading = true;
            state.error = false;
            state.scanResult = null;
            state.scanResultWithType = null;
        });

        builder.addCase(socialSearcher.rejected, (state, action) => {
            state.loading = false;
            state.error = true;
        })

        builder.addCase(socialScanTrigger.fulfilled, (state, action) => {
            state.loading = false;
            state.error = false;
            state.success = true;
        });

        builder.addCase(socialScanTrigger.pending, (state, action) => {
            state.loading = true;
            state.secondPhase = true;
            state.error = false;
            state.success = false;
        });

        builder.addCase(socialScanTrigger.rejected, (state, action) => {
            state.loading = false;
            state.error = true;
        });

        builder.addCase(socialScanResultsById.fulfilled, (state, action) => {
            state.scanResultsByIdLoading = false;
            state.selectedDateResult = action.payload.data;
            state.error = false;
        });

        builder.addCase(socialScanResultsById.pending, (state, action) => {
            state.scanResultsByIdLoading = true;
            state.selectedDateResult = {};
            state.error = false;
        });

        builder.addCase(socialScanResultsById.rejected, (state, action) => {
            state.scanResultsByIdLoading = false;
            state.error = true;
        });
    }
});

export const {
    resetPhase,
    selectedResult,
    selectedResultId,
    selectedResultName,
    selectResultFinalData,
    setSocialSelectedTab,
} = socialSlice.actions;

export default socialSlice.reducer;
