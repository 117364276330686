import API from "../configs/axiosInstance";

const getAllEmailLeakages = (domainName, createdDate) => {
  return API({
    url: `account-service/v1/domain-mail-leakages/pageable`,
    method: "POST",
    data: {
      domainName,
      createdDate,
    },
  });
};

const getScanStatus = (scanId) => {
  return API({
    url: `osint-service/scanStatus`,
    method: "POST",
    data: {
      id: scanId,
    },
  });
};

const getScanSummary = (scanId, by) => {
  return API({
    url: `osint-service/scanSummary`,
    method: "POST",
    data: {
      id: scanId,
      by,
    },
  });
};

const getScanEventResults = (scanId, eventType) => {
  return API({
    url: `osint-service/scanEventResults`,
    method: "POST",
    data: {
      id: scanId,
      eventType,
    },
  });
};


const startEmailLeakageScan = (domainId) => {
  return API({
    url: `account-service/v1/domain-mail-leakages/scan?domainId=${domainId}`,
    method: "POST",
  });
};

const getMailSearchByDomain = (domainId) => {
  return API({
    url: `account-service/v2/domain-mail-leakages/${domainId}`,
    method: "POST",
  });
};

const searchLeakageByEmail = (leakageMailId, email) => {
  return API({
    url: `account-service/v2/domain-mail-leakages/${leakageMailId}/search-leakage`,
    method: "POST",
    params: {
      email,
    },
  });
};

const searchLeakageByCustomEmail = (email) => {
  return API({
    url: `account-service/v2/domain-mail-leakages/${email}/search-leakage`,
    method: "GET",
  });
}

const getEmailLeakagesPageable = (params = {}, data = {}) => {
  return API({
    url: `account-service/v2/domain-mail-leakages/pageable`,
    method: "POST",
    data,
    params
  });
};

const postMailLeakagesVip = (formattedData) => {
  return API({
    url: `account-service/v1/mail-leakages-vip`,
    method: "POST",
    data: formattedData
  });
}

const getVipEmailLeakagesPageable = (params = {}, data = {}) => {
  return API({
    url: `account-service/v1/mail-leakages-vip/pageable`,
    method: "POST",
    data,
    params
  });
}

const getVipEmailLeakagesDetails = (id) => {
  return API({
    url: `account-service/v1/mail-leakages-vip/data/${id}`,
    method: "GET",
  });
}

const getEmailLeakageService = {
  getAllEmailLeakages,
  getScanStatus,
  getScanSummary,
  getScanEventResults,
  startEmailLeakageScan,
  getMailSearchByDomain,
  searchLeakageByEmail,
  getEmailLeakagesPageable,
  searchLeakageByCustomEmail,
  postMailLeakagesVip,
  getVipEmailLeakagesPageable,
  getVipEmailLeakagesDetails
};

export default getEmailLeakageService;
