import API from "../configs/axiosInstance";

const getSsl = (params = {},data = {}) => {
    return API({
        url: `/account-service/v1/domains/certificates`,
        method: 'POST',
        data,
        params
    });
};

const getSslDetails = (id) => {
    return API({
        url: `/account-service/v1/domains/certificates/${id}/details`,
        method: 'GET',
    });
};

const sendTickets = (data) => {
    return API({
        url: `/account-service/v1/tickets`,
        method: 'POST',
        data
    });
}

const getLoginHistory = (params = {}, data = {}) => {
    return API({
        url: `/account-service/v1/login-history/pageable`,
        method: 'POST',
        data,
        params
    });
};

const getCurrentNotificationSettings = () => {
    return API({
        url:'/account-service/v1/notification-settings',
        method:'GET',
    })
}

const updateNotificationSettings = (data) => {
    return API({
        url:'/account-service/v1/notification-settings',
        method:'PUT',
        data
    })
}

const updatePasswordWhileLoggedIn = (data) => {
    return API({
        url:'/account-service/v1/users/password',
        method:'PUT',
        data
    })
}

const createApiKey = (data) => {
    return API({
        url: `/account-service/v1/api-keys`,
        method: 'POST',
        data
    });
};

const updateApiKey = (keyId, data) => {
    return API({
        url: `/account-service/v1/api-keys/${keyId}`,
        method: 'PUT',
        data
    });
};

const getApiKey = () => {
    return API({
        url: `/account-service/v1/api-keys`,
        method: 'GET',
    });
};

const settingsService = {
    getSsl,
    getSslDetails,
    sendTickets,
    getLoginHistory,
    getCurrentNotificationSettings,
    updateNotificationSettings,
    updatePasswordWhileLoggedIn,
    createApiKey,
    updateApiKey,
    getApiKey
};

export default settingsService;
